import React from "react"
import Link from "gatsby-plugin-transition-link/AniLink"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const infoImage = graphql`
    fragment infoImage on File {
        childImageSharp {
            fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`


function Info() {

  const data = useStaticQuery(graphql`
      query {
          imageOne: file(relativePath: { eq: "ginger-cat-715.png" }) {
              ...infoImage
          }
          imageTwo: file(relativePath: { eq: "ginger-cat-759.png" }) {
              ...infoImage
          }
          imageThree: file(relativePath: { eq: "ginger-cat-727.png" }) {
              ...infoImage
          }
          imageFour: file(relativePath: { eq: "ginger-cat-741.png" }) {
              ...infoImage
          }
          imageFive: file(relativePath: { eq: "ginger-cat-753.png" }) {
              ...infoImage
          }
      }
  `)

  return (
    <div className="text-center">
      <div className="display-1 my-5 text-orange-600 uppercase text-center">
        Rate out of 10.
      </div>
      <div className="text-muted mx-5 my-4 h3 info">
        A joke website made for friends during isolation.
      </div>
      <h3 className="text-orange-600 mx-5 h2 info">
        <Link
          paintDrip
          className="uppercase"
          duration={0.5}
          hex="#ff8a00"
          to="/mug">
          Go to latest mug.
        </Link>
      </h3>
      <div className="section-header">
        <div className="row items-center">
          <div className="col-md-6">
            <Img fluid={data.imageOne.childImageSharp.fluid}
                 alt="Picture of a mug"
            />
          </div>
          <div className="col-md-6">
            Every day, a new picture of a mug will be uploaded.
          </div>
        </div>
      </div>
      <div className="section-header">
        <div className="row items-center">
          <div className="col-md-6">
            You, the good people of the internet, will vote on this mug and leave a message.
          </div>
          <div className="col-md-6">
            <Img fluid={data.imageTwo.childImageSharp.fluid}
                 alt="Vote on mug"
            />
          </div>
        </div>
      </div>
      <div className="section-header">
        <div className="row h-100 items-center">
          <div className="col-md-6">
            <Img fluid={data.imageThree.childImageSharp.fluid}
                 alt="statistics"
            />
          </div>
          <div className="col-md-6">
            At the end of the day, the results will be added and the information will be shown. There will even be a winning comment!
          </div>
        </div>
      </div>
      <div className="section-header">
        <div className="row items-center">
          <div className="col-md-6">
            Eventually you'll be able to upload your own mugs and images for others to rate! Don't forget to check back every day for all the updates.
          </div>
          <div className="col-md-6">
            <Img fluid={data.imageFour.childImageSharp.fluid}
                 alt="Upload image"
            />
          </div>
        </div>
      </div>
      <div className="section-header">
        <div className="row items-center">
          <div className="col-md-12">
            <Img fluid={data.imageFive.childImageSharp.fluid}
                 alt="Cat on plane"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Info
