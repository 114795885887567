import React from "react"
import "../style.css"
import Appbar from "../components/appbar"
import Info from "../components/info"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

function index() {
  return (
    <div className="flex flex-col h-full">
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Rate Out Of 10</title>
      </Helmet>
      <div className="flex-1 mx-auto p-8">
        <Appbar/>
        <Info/>
        <Footer/>
      </div>
    </div>

  )
}

export default index
